<template>
  <div class="project">
    <div class="row">
      <!-- 项目概况 -->
      <div class="project-intro">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">项目概况</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="project-intro-wrap">
                <div class="project-intro-wrap-top">
                  <div class="project-intro-wrap-top-item">
                    <p class="name">占地面积</p>
                    <p style="color: #fff5b5">
                      {{ projectData.areaCovered }}㎡
                    </p>
                  </div>
                  <div class="project-intro-wrap-top-item">
                    <p class="name">楼栋数量</p>
                    <p style="color: #f5b1ff">{{ projectData.builTotal }}栋</p>
                  </div>
                  <div class="project-intro-wrap-top-item">
                    <p class="name">建筑面积</p>
                    <p style="color: #ffb0b0">
                      地上{{ projectData.aboveAreaCovered }}㎡
                    </p>
                    <p style="color: #ffb0b0">
                      地下{{ projectData.unaboveAreaCovered }}㎡
                    </p>
                  </div>
                </div>
                <ul>
                  <li>
                    结构形式：<span>{{ projectData.structType }}</span>
                  </li>
                  <li>
                    项目地址：<span>{{ projectData.proAddress }}</span>
                  </li>
                  <li>
                    建设单位：<span>{{ projectData.devoName }}</span>
                  </li>
                  <li>
                    设计单位：<span>{{ projectData.designName }}</span>
                  </li>
                  <li>
                    监理单位：<span>{{ projectData.supervisorName }}</span>
                  </li>
                  <li>
                    施工单位：<span>{{ projectData.construtName }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 轮播图 -->
      <div class="project-main" @mouseover="stopSetMainMenu = true" @mouseout="stopSetMainMenu = false">
        <div class="project-main-menu">
          <span class="project-main-menu-item" :class="[mainMenu == 1 ? 'project-main-menu-item-active' : '']" @click="mainMenu = 1">效果图</span>
          <span class="project-main-menu-item" :class="[mainMenu == 2 ? 'project-main-menu-item-active' : '']" @click="mainMenu = 2">规划图</span>
          <span class="project-main-menu-item" :class="[mainMenu == 3 ? 'project-main-menu-item-active' : '']" @click="mainMenu = 3">实景图</span>
          <span class="project-main-menu-item" :class="[mainMenu == 4 ? 'project-main-menu-item-active' : '']" @click="mainMenu = 4">视频展示</span>
        </div>
        
        <img v-show="mainMenu == 1" :src="'api/' + projectData.proEffectUrl" width="100%" height="100%">
        <img v-show="mainMenu == 2" :src="'api/' + projectData.proPlanUrl" width="100%" height="100%">
        <img v-show="mainMenu == 3" :src="'api/' + projectData.proRealUrl" width="100%" height="100%">
        <video v-if="mainMenu == 4" width="100%" height="100%" controls autoplay loop>
          <source :src="'api/' + projectData.proVideoUrl" type="video/mp4">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <!-- 监测信息 -->
      <div class="project-device">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">监测信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div
                id="echarts-project-r1-c3"
                style="width: 100%; height: 100%"
              ></div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二行 -->
    <div class="row">
      <!-- 工种分布 -->
      <div class="project-type-of-work">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">工种分布</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <div
                id="echarts-type-of-work"
                style="width: 100%; height: 100%"
              ></div>
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 项目进度 -->
      <div class="project-progress">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">项目进度</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <div class="pointsListWrap">
                <div 
                  v-for="(item, index) in pointListWrapCount" 
                  :key="index" 
                  :class="[
                    index%2 == 0 ? 'pointItemWrap' : 'pointLineWrap',
                    index == pointActiveIndex ? 'pointItemWrapActive' : '',
                    index%2 != 0 && index > pointActiveIndex ? 'pointLineWrapBlue' : '',
                  ]"
                >
                  <img v-if="index%2 == 0 && index < pointActiveIndex" class="potintImg" src="../assets/project/point_finish.png">
                  <img v-if="index%2 == 0 && index > pointActiveIndex" class="potintImg" src="../assets/project/point.png">
                  <img v-if="index%2 == 0 && index == pointActiveIndex" class="activeImg" src="../assets/project/point_active.png">
                  <div v-if="index%2 == 0" class="pointItemName">{{pointsList[getPotintIndex(index)].scheduleName}}</div>
                  <div v-if="index%2 == 0" class="pointItemDate">{{pointsList[getPotintIndex(index)].scheduleDate}}</div>
                </div>
              </div>
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 人员监控 -->
      <div class="project-personnel">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">人员监控</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <div
                id="echarts-project-personnel"
                style="width: 100%; height: 100%"
              ></div>
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@Width: 192rem;
.row-height-1 {
  height: 510 / @Width;
}
.row-height-2 {
  height: 160 / @Width;
}

.project {
  width: 100%;
  height: 100%;
  // background-color: #fff;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20 / @Width;
  }
  .project-intro {
    width: 400 / @Width;
    height: 585 / @Width;
    // background-color: #f00;
    .project-intro-wrap-top {
      display: flex;
      justify-content: space-between;
      .project-intro-wrap-top-item {
        p {
          text-align: center;
          font-weight: 700;
          font-size: 18 / @Width;
        }
        .name {
          color: #00e4ff;
          font-weight: normal;
          font-size: 16 / @Width;
          margin-bottom: 14 / @Width;
        }
      }
    }
    ul {
      margin-left: 20 / @Width;
    }
    li {
      color: #00e4ff;
      font-size: 16 / @Width;
      margin-bottom: 10 / @Width;
      span {
        color: #fff;
      }
    }
  }
  .project-main {
    width: 1000 / @Width;
    height: 585 / @Width;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;

    .project-main-menu {
      width: 400 / @Width;
      height: 40 / @Width;
      font-size: 20 / @Width;
      
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      left: 300 / @Width;
      border-radius: 10/@Width 10/@Width 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 888;

      .project-main-menu-item {
        color: #00e4ff;
        display: block;
        width: 100 / @Width;
        text-align: center;
      }

      .project-main-menu-item-active {
        color: #fff;
      }
    }
  }
  .project-device {
    width: 400 / @Width;
    height: 585 / @Width;
    // background-color: green;
  }
  .project-type-of-work {
    width: 400 / @Width;
    height: 235 / @Width;
    // background-color: pink;
  }
  .project-progress {
    width: 1000 / @Width;
    height: 235 / @Width;
    // background-color: #f0f;
  }
  .project-personnel {
    width: 400 / @Width;
    height: 235 / @Width;
    // background-color: olive;
  }
  .pointsListWrap {
    width: 850 / @Width;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .pointItemWrap {
      margin-bottom: 20 / @Width;
      position: relative;
      text-align: center;
      .pointItemName {
        width: 120/ @Width;
        position: absolute;
        top: -40 / @Width;
        left: -32 / @Width;
        color: #f8b551;
        font-size: 18 / @Width;
        font-weight: 700;
        white-space:nowrap;
        overflow: hidden;
      }
      .pointItemDate {
        width: 100/ @Width;
        position: absolute;
        bottom: -50 / @Width;
        left: -22 / @Width;
        box-sizing: border-box;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        white-space:nowrap;
        overflow: hidden;
        padding: 5 / @Width 0;
        font-size: 16 / @Width;
      }
    }
    .pointItemWrapActive {
      margin-bottom: 20 / @Width;
      position: relative;
      text-align: center;
      .pointItemName {
        width: 120/ @Width;
        position: absolute;
        top: -33 / @Width;
        left: -25 / @Width;
        color: #f8b551;
        font-size: 18 / @Width;
        font-weight: 700;
        white-space:nowrap;
        overflow: hidden;
      }
      .pointItemDate {
        width: 100/ @Width;
        position: absolute;
        bottom: -43 / @Width;
        left: -15 / @Width;
        box-sizing: border-box;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        white-space:nowrap;
        overflow: hidden;
        padding: 5 / @Width 0;
        font-size: 16 / @Width;
      }
    }
    .pointLineWrap {
      flex-grow: 1;
      border-top: 10 / @Width dashed #f8b551;
      margin: 0 10 / @Width;
      margin-bottom: 25 / @Width;
    }
    .pointLineWrapBlue {
      border-top: 10 / @Width dashed #00e4ff;
    }
  }
}
.border-wrap {
  .flex {
    display: flex;
  }
  .grow {
    flex-grow: 1;
  }
  .border-top {
    .border-top-left,
    .border-top-right {
      width: 50 / @Width;
      height: 50 / @Width;
      img {
        width: 50 / @Width;
        height: 50 / @Width;
      }
    }
    .border-top {
      height: 50 / @Width;
      img {
        width: 100%;
        height: 50 / @Width;
      }
    }
    .border-title {
      width: 205 / @Width;
      height: 50 / @Width;
      background-image: url("../assets/border/border-title.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      .border-name {
        color: #00e4ff;
        font-size: 18 / @Width;
        font-weight: 700;
        text-align: center;
        margin-top: 8 / @Width;
      }
    }
  }

  .border-body {
    .border-left,
    .border-right {
      width: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 100%;
      }
    }
    .border-body-middle {
      background-image: url("../assets/border/border-body-bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }

  .border-bottom {
    .border-bottom-left,
    .border-bottom-right {
      width: 25 / @Width;
      height: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 25 / @Width;
      }
    }
    .border-bottom {
      height: 25 / @Width;
      img {
        width: 100%;
        height: 25 / @Width;
      }
    }
    .border-bottom-middle {
      width: 258 / @Width;
      height: 25 / @Width;
      img {
        width: 258 / @Width;
        height: 25 / @Width;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Project",
  data() {
    return {
      projectData: {
        proName: "",
        structType: "",
        areaCovered: "",
        proStartTime: "",
        checkAccpetTime: "",
        builTotal: "",
        aboveAreaCovered: "",
        unaboveAreaCovered: "",
        proAddress: "",
        devoName: "",
        construtName: "",
        designName: "",
        supervisorName: "",
      },
      mainMenu: 1,
      stopSetMainMenu: false,
      getR2C1Data: [],
      getR2C3Data: [],
      getR1C3Data: [],
      echarts: {
        echartsProjectR1C3: null,
        typeOfWork: null,
        projectPersonnel: null,
      },
      pointsList: [],
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    id: function () {
      return parseInt(this.$route.params.id);
    },
    pointListWrapCount: function () {
      if (this.pointsList.length) {
        return this.pointsList.length * 2 - 1
      } else {
        return 0
      }
    },
    pointActiveIndex: function () {
      var activeIndex = 0
      var time = Date.parse(new Date())
      this.pointsList.forEach((item, index) => {
        if (time >  Date.parse(new Date(item.scheduleDate))) {
          activeIndex = index
        }
      })
      return activeIndex * 2
    }
  },
  watch: {
    // projectList(newVal, oldVal) {
    //   console.log("watch projectList=>", newVal);
    // },
    currentProject(newVal, oldVal) {
      console.log("watch currentProject=>", newVal);
      this.onGetProjectData(); // 获取R1C1数据（项目概况）
      this.onGetPointsData(); // 获取项目进度
      this.onGetR1C3Data(); // 获取 R1C3 数据 （监测信息）（设备在线率）
      this.onGetR2C1Data(); // 获取R2C1数据（总人数按工种分布）
      this.onGetR2C3Data(); // 获取R2C3数据（人员监控 折线图）
    },
    id(newVal, oldVal) {
      console.log("watch id=>", newVal);
      this.onToggleCurrentProject(); // 根据 SSO 传入 id 自动加载对应项目
    },
  },
  mounted() {
    this.onGetProjectData(); // 获取项目概况
    this.onGetPointsData(); // 获取项目进度
    this.onGetR1C3Data(); // 获取 R1C3 数据 （监测信息）（设备在线率）
    this.onGetR2C1Data(); // 获取R2C1数据（总人数按工种分布）
    this.onGetR2C3Data(); // 获取R2C3数据（人员监控 折线图）
    setTimeout(() => {
      this.onToggleCurrentProject(); // 根据 SSO 传入 id 自动加载对应项目
    }, 500);
    setInterval(() => {
      this.setMainMenu() // 自动切换主页图片及视频
    }, 5000);
  },
  beforeDestroy() {
    this.echarts.echartsProjectR1C3.dispose();
    this.echarts.typeOfWork.dispose();
    this.echarts.projectPersonnel.dispose();
  },
  methods: {
    // 获取R1C1数据（项目概况）
    onGetProjectData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      this.$axiosAdmin({
        method: "get",
        url: `api/admin/project/getProject/${this.currentProject.id}`,
      })
        .then((res) => {
          if (res.code === 0) {
            that.projectData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取R2C2数据（项目进度）
    onGetPointsData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/prProgressPoints/sclectPoints`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          console.log('onGetPointsData =>', res);
          this.pointsList = res.data

          var time = new Date();
          var format = function (val) {
            if (val < 10) {
              return "0" + val;
            } else {
              return val;
            }
          };
          var today = `${time.getFullYear()}-${format(time.getMonth()+1)}-${format(time.getDate())}`
          var startDate = Date.parse(res.data[0].scheduleDate)
          var endDate = Date.parse(today)
          if (startDate > endDate) {
            return 0
          }
          if (startDate == endDate) {
            return 1
          }
          var days = (endDate - startDate) / (1*24*60*60*1000)
          that.$store.commit("editSafeDay", days);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取R2C1数据（总人数按工种分布）
    onGetR2C1Data: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/wgconsumer/PercentageOfPopulation`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.getR2C1Data = res.data;
          that.echartsTypeOfWorkInit();
          if (res.code === 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取 R1C3 数据 （监测信息）（设备在线率）
    onGetR1C3Data: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/specialEquipment/StatisticalPercentage`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.getR1C3Data = res.data;
          that.echartsProjectR1C3Init();
          if (res.code === 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取R2C3数据（人员监控 折线图）
    onGetR2C3Data: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/wgRecord/dailyStatistical`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.getR2C3Data = res.data;
          that.echartsProjectPersonnelInit();
          if (res.code === 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // echartsProjectR1C3
    echartsProjectR1C3Init: function () {
      this.echarts.echartsProjectR1C3 = this.$echarts
        .init(document.getElementById("echarts-project-r1-c3"))
        .dispose();
      this.echarts.echartsProjectR1C3 = this.$echarts.init(
        document.getElementById("echarts-project-r1-c3")
      );
      var categoryName = [];
      var categoryList = [];
      var seriesItemData = [];
      var seriesData = [];
      this.getR1C3Data.forEach((item) => {
        if (item.deviceType == "1") categoryName.push("塔机");
        if (item.deviceType == "2") categoryName.push("卸料机");
        if (item.deviceType == "3") categoryName.push("升降机设备");
        if (item.deviceType == "8") categoryName.push("扬尘");
        categoryList.push(parseFloat(item.percents));
        seriesItemData.push(0);
      });
      this.getR1C3Data.forEach((item, index) => {
        var seriesItem = {
          type: "bar",
          barWidth: "50%",
          data: JSON.parse(JSON.stringify(seriesItemData)),
          coordinateSystem: "polar",
          name: "",
          stack: "a",
          roundCap: true,
          itemStyle: {
            // color: "#BFA27C",
            barBorderRadius: 5,
          },
          showBackground: true,
          backgroundStyle: {
            color: "#000",
            opacity: 0,
            barBorderRadius: 50,
          },
        };
        if (item.deviceType == "1") seriesItem.name = "塔机";
        if (item.deviceType == "2") seriesItem.name = "卸料机";
        if (item.deviceType == "3") seriesItem.name = "升降机设备";
        if (item.deviceType == "8") seriesItem.name = "扬尘";
        seriesItem.data[index] = categoryList[index];
        seriesData.push(seriesItem);
      });
      console.log("seriesData=>", seriesData);
      var option = (option = {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        angleAxis: {
          type: "value",
          min: 0,
          max: 100,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          data: categoryName,
          z: 100,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            margin: 6,
            fontSize: 10,
            formatter: function (value, index) {
              var list = categoryList;
              // return value + ":" + list[index] + "%";
              return list[index] + "%";
            },
            textStyle: {
              color: "#00e4ff",
            },
            interval: 0,
          },
        },
        polar: {
          center: ["51%", "40%"],
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: seriesData,
        legend: {
          bottom: 10,
          // icon: "circle",
          itemWidth: 7,
          itemHeight: 7,
          show: true,
          textStyle: {
            color: "#00e4ff",
            fontSize: 12,
          },
        },
      });
      this.echarts.echartsProjectR1C3.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsProjectR1C3.resize();
      });
    },
    // 工种分布 饼图
    echartsTypeOfWorkInit: function () {
      this.echarts.typeOfWork = this.$echarts
        .init(document.getElementById("echarts-type-of-work"))
        .dispose();
      this.echarts.typeOfWork = this.$echarts.init(
        document.getElementById("echarts-type-of-work")
      );

      var listData = this.getR2C1Data;
      var data = [];
      var legend = [];
      listData.forEach((item, index) => {
        data.push({
          name: item.job,
          value: item.totals,
        });
        legend.push(item.job);
      });
      var option = {
        legend: {
          data: legend,
          type: 'scroll',
          orient: "vertical",
          bottom: 0,
          right: 0,
          textStyle: {
            color: "#00e4ff",
          },
          itemWidth: 7,
          itemHeight: 7,
          pageTextStyle: {
            color: "#00e4ff",
          },
          formatter: function (name) {
            var arr;
            listData.forEach((item, index) => {
              if (name == item.job) {
                arr = item.job + " " + item.totals + "人 " + item.percents;
              }
            });
            return arr;
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "工种分布",
            type: "pie",
            center: ["35%", "50%"],
            radius: ["40%", "70%"],
            label: {
              formatter: "{d}%",
              color: "#00e4ff",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data,
          },
        ],
      };
      this.echarts.typeOfWork.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.typeOfWork.resize();
      });
    },
    // 人员监控 折线图
    echartsProjectPersonnelInit: function () {
      this.echarts.projectPersonnel = this.$echarts
        .init(document.getElementById("echarts-project-personnel"))
        .dispose();
      this.echarts.projectPersonnel = this.$echarts.init(
        document.getElementById("echarts-project-personnel")
      );
      var listData = this.getR2C3Data;
      var xData = [];
      var yData = [];
      listData.forEach((item, index) => {
        xData.push(item.days);
        yData.push(item.counts);
      });
      var option = {
        grid: {
          // x: 35,
          // y: 20,
          // x2: 5,
          // y2: 20,
          top: 10,
          bottom: 40,
          left: 40,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
            // formatter: function (value, index) {
            //   if (index % 2 != 0) {
            //     return "\n" + value;
            //   } else {
            //     return value;
            //   }
            // },
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              color: "rgba(255,225,255,.1)",
            },
          },
        },
        series: [
          {
            data: yData,
            type: "line",
            smooth: false,
            lineStyle: {
              normal: {
                width: 2,
                color: {
                  type: "linear",
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(249,165,137, 0.5)",
                    },
                    {
                      offset: 1,
                      color: "rgba(249,165,137, 0.5)",
                    },
                  ],
                  globalCoord: false,
                },
                shadowColor: "rgba(249,165,137, 0.5)",
                shadowBlur: 10,
                shadowOffsetY: 7,
              },
            },
            itemStyle: {
              normal: {
                color: "#F6D06F",
                borderWidth: 10,
                borderColor: "#F6D06F",
              },
            },
          },
          {
            data: yData,
            type: "bar",
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(249,165,137, 0)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(249,165,137, 0.1)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
                barBorderRadius: 0,
              },
            },
          },
        ],
      };
      this.echarts.projectPersonnel.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.projectPersonnel.resize();
      });
    },
    // 根据 SSO 传入 id 自动加载对应项目
    onToggleCurrentProject: function () {
      if (!this.id) {
        return
      }
      this.projectList.forEach((item, index) => {
        if (this.id == item.id) {
          this.$store.commit("editCurrentProject", index);
          console.log("自动跳转项目=>", index);
        }
      });
    },

    // 获取进度条点位对应数据下标
    getPotintIndex: function (index) {
      if (index == 0) {
        return 0
      } else {
        return index / 2
      }

    },
    // 自动切换首页项目图片或视频
    setMainMenu: function () {
      if (this.stopSetMainMenu) {
        return
      }
      if (this.mainMenu < 4) {
        this.mainMenu = this.mainMenu + 1
      } else {
        this.mainMenu = 1
      }
    }
  },
};
</script>